import React, { useState } from 'react'
import { Grid } from '@mui/material'
import logo from "../../image/logo.svg"
import facebook from "../../image/facebook.svg"
import twitter from "../../image/twitter.svg"
import linkedin from "../../image/linkedin.svg"
import insta from "../../image/insta.svg"
import youtube from "../../image/youtube.svg"
import './Footer.css'
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom'






function Footer() {

    const [countries, setCountries] = useState([
        { name: 'About Us' },
        { name: 'Find store' },
        { name: 'Categories' },
        { name: 'Blogs' },
        { name: 'About Us' },
        { name: 'Find store' },
        { name: 'Categories' },
        { name: 'Blogs' },
        { name: 'Help Center' },
        { name: 'Money Refund' },
        { name: 'Shipping' },
        { name: 'Contact us' },
        { name: 'Login' },
        { name: 'Register' },
        { name: 'Settings' },
        { name: 'My Orders' },

    ])
    return (
        <div className='footer'>
            <Grid container spacing={0} >
                <Grid xs={12} sm={12} md={12} lg={10} xl={10}>
                    <Grid container spacing={0} sx={{ justifyContent: 'space-between' }}>
                        <Grid xs={12} sm={12} md={12} lg={4} xl={4}>
                            <div className='footer-left'>
                                <Link to="/"><img src={logo} alt='hlogo' /></Link>
                                <div className='fl-para'>
                                    Best information about the company gies here but now lorem ipsum is . Best information about the company gies here but now lorem ipsum is . Best information about the company gies here but now lorem ipsum is
                                </div>
                                <div className='socila-icon'>
                                    <ul>
                                        <li><Link><img src={facebook} alt='hlogo' /></Link></li>
                                        <li><Link><img src={twitter} alt='hlogo' /></Link></li>
                                        <li><Link><img src={linkedin} alt='hlogo' /></Link></li>
                                        <li><Link><img src={insta} alt='hlogo' /></Link></li>
                                        <li><Link><img src={youtube} alt='hlogo' /></Link></li>

                                    </ul>
                                </div>
                            </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Stack direction='row' spacing={0} sx={{ textAlign: 'var(--txt-al-l)', justifyContent: 'space-around' }} className='country-stck2'>
                                <Stack spacing={2}>
                                    <div className='fot-cat'>About</div>
                                    {countries.slice(0, 3 + 1).map((row, ind) => {
                                        return (
                                            <div className='ft-inner-txt'>
                                                {row.name}
                                            </div>
                                        )
                                    })}
                                </Stack>
                                <Stack spacing={2}>
                                    <div className='fot-cat'>Partnership</div>

                                    {countries.slice(4, 7 + 1).map((row, ind) => {
                                        return (
                                            <div className='ft-inner-txt'>
                                                {row.name}
                                            </div>
                                        )
                                    })}

                                </Stack>
                                <Stack spacing={2}>
                                    <div className='fot-cat'>Information</div>

                                    {countries.slice(8, 11 + 1).map((row, ind) => {
                                        return (
                                            <div className='ft-inner-txt'>
                                                {row.name}
                                            </div>
                                        )
                                    })}

                                </Stack>
                                <Stack spacing={2}>
                                    <div className='fot-cat'>For users</div>
                                    {countries.slice(11 + 1).map((row, ind) => {
                                        return (
                                            <div className='ft-inner-txt'>
                                                {row.name}
                                            </div>
                                        )
                                    })}

                                </Stack>
                                <Stack spacing={2}>
                                    <div className='fot-cat'>For users</div>
                                    {countries.slice(11 + 1).map((row, ind) => {
                                        return (
                                            <div className='ft-inner-txt'>
                                                {row.name}
                                            </div>
                                        )
                                    })}

                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Footer
