import React from "react";
import "./Productlist.css";
import Grid from "@mui/material/Grid";
import { Button, IconButton } from "@mui/material";
import Badge from "@mui/material/Badge";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import Like from "../../Components/Productlist/Like";
import Rating from "../Productlist/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import image1 from "../../image/4.png";
import SideBar from "../SideBar/SideBar";   
import Typography from '@mui/material/Typography';
import Header from '../Header/Header'
import Footer from '../Footer/Footer'




function Productlist() {
  return (
    <div className="Productlist">
      <div>
        <Header />
      </div>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div className="top-panel">
          <div className="cctv-camera">
            <div className="cctv-font">CCTV Camera</div>
          </div>
          <div className="top-left">
            <Typography>
                {/* <ul className="top-right-list">
                    <li><span className="top-right">HOME</span></li>
                    <li><span className="top-right">SMART CAMERAS</span></li>
                    <li><span className="top-right">IP CAMERAS</span></li>
                    <li><span className="top-right">VALUE EXPRESS IP CAMERAS</span></li>
                </ul> */}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid container spacing={2} sx={{ justifyContent: "center" }}>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          <div className="leftside">
            <div className="sidebar">
              <SideBar />
            </div>
            <div className="subseries"></div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
          <div className="cards-container">
            <div className="cards">
              <div className="likeandhot">
                <Like />
                <Button className="spanhot1">HOT</Button>
              </div>
              <div className="product-img">
                <img src={image1} alt="product-image" className="camera-1" />
              </div>
              <div className="link-snack1">Category</div>
              <div className="link-fresh1">
                2 MP Build-in Mic Fixed Bullet Network Camera
              </div>
              <div className="price">
                <div className="price-clr">
                  <p className="b1">₹2000</p>
                  <span className="div4">₹5000</span>z
                  <div className="rating">
                    <Rating />
                  </div>
                </div>
              </div>
              <div className="compare">
                <div className="frame-parent2">
                  <input className="frame-input" type="checkbox" />
                  <b className="link-vegetables1">Compare</b>
                </div>
                <div className="addtocart">
                  <Button className="buy-now">Buy Now</Button>
                  <div className="badge">
                    <Badge>
                      <LocalGroceryStoreIcon />
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
            <div className="cards">
              <div className="likeandhot">
                <Like />
                <Button className="spanhot1">HOT</Button>
              </div>
              <div className="product-img">
                <img src={image1} alt="product-image" className="camera-1" />
              </div>
              <div className="link-snack1">Category</div>
              <div className="link-fresh1">
                2 MP Build-in Mic Fixed Bullet Network Camera
              </div>
              <div className="price">
                <div className="price-clr">
                  <p className="b1">₹2000</p>
                  <span className="div4">₹5000</span>
                  <div className="rating">
                    <Rating />
                  </div>
                </div>
              </div>
              <div className="compare">
                <div className="frame-parent2">
                  <input className="frame-input" type="checkbox" />
                  <b className="link-vegetables1">Compare</b>
                </div>
                <div className="addtocart">
                  <Button className="buy-now">Buy Now</Button>
                  <div className="badge">
                    <Badge>
                      <LocalGroceryStoreIcon />
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
            <div className="cards">
              <div className="likeandhot">
                <Like />
                <Button className="spanhot1">HOT</Button>
              </div>
              <div className="product-img">
                <img src={image1} alt="product-image" className="camera-1" />
              </div>
              <div className="link-snack1">Category</div>
              <div className="link-fresh1">
                2 MP Build-in Mic Fixed Bullet Network Camera
              </div>
              <div className="price">
                <div className="price-clr">
                  <p className="b1">₹2000</p>
                  <span className="div4">₹5000</span>
                  <div className="rating">
                    <Rating />
                  </div>
                </div>
              </div>
              <div className="compare">
                <div className="frame-parent2">
                  <input className="frame-input" type="checkbox" />
                  <b className="link-vegetables1">Compare</b>
                </div>
                <div className="addtocart">
                  <Button className="buy-now">Buy Now</Button>
                  <div className="badge">
                    <Badge>
                      <LocalGroceryStoreIcon />
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
            <div className="cards">
              <div className="likeandhot">
                <Like />
                <Button className="spanhot1">HOT</Button>
              </div>
              <div className="product-img">
                <img src={image1} alt="product-image" className="camera-1" />
              </div>
              <div className="link-snack1">Category</div>
              <div className="link-fresh1">
                2 MP Build-in Mic Fixed Bullet Network Camera
              </div>
              <div className="price">
                <div className="price-clr">
                  <p className="b1">₹2000</p>
                  <span className="div4">₹5000</span>
                  <div className="rating">
                    <Rating />
                  </div>
                </div>
              </div>
              <div className="compare">
                <div className="frame-parent2">
                  <input className="frame-input" type="checkbox" />
                  <b className="link-vegetables1">Compare</b>
                </div>
                <div className="addtocart">
                  <Button className="buy-now">Buy Now</Button>
                  <div className="badge">
                    <Badge>
                      <LocalGroceryStoreIcon />
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="cards-container">
            <div className="cards">
              <div className="likeandhot">
                <Like />
                <Button className="spanhot1">HOT</Button>
              </div>
              <div className="product-img">
                <img src={image1} alt="product-image" className="camera-1" />
              </div>
              <div className="link-snack1">Category</div>
              <div className="link-fresh1">
                2 MP Build-in Mic Fixed Bullet Network Camera
              </div>
              <div className="price">
                <div className="price-clr">
                  <p className="b1">₹2000</p>
                  <span className="div4">₹5000</span>
                  <div className="rating">
                    <Rating />
                  </div>
                </div>
              </div>
              <div className="compare">
                <div className="frame-parent2">
                  <input className="frame-input" type="checkbox" />
                  <b className="link-vegetables1">Compare</b>
                </div>
                <div className="addtocart">
                  <Button className="buy-now">Buy Now</Button>
                  <div className="badge">
                    <Badge>
                      <LocalGroceryStoreIcon />
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
            <div className="cards">
              <div className="likeandhot">
                <Like />
                <Button className="spanhot1">HOT</Button>
              </div>
              <div className="product-img">
                <img src={image1} alt="product-image" className="camera-1" />
              </div>
              <div className="link-snack1">Category</div>
              <div className="link-fresh1">
                2 MP Build-in Mic Fixed Bullet Network Camera
              </div>
              <div className="price">
                <div className="price-clr">
                  <p className="b1">₹2000</p>
                  <span className="div4">₹5000</span>
                  <div className="rating">
                    <Rating />
                  </div>
                </div>
              </div>
              <div className="compare">
                <div className="frame-parent2">
                  <input className="frame-input" type="checkbox" />
                  <b className="link-vegetables1">Compare</b>
                </div>
                <div className="addtocart">
                  <Button className="buy-now">Buy Now</Button>
                  <div className="badge">
                    <Badge>
                      <LocalGroceryStoreIcon />
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
            <div className="cards">
              <div className="likeandhot">
                <Like />
                <Button className="spanhot1">HOT</Button>
              </div>
              <div className="product-img">
                <img src={image1} alt="product-image" className="camera-1" />
              </div>
              <div className="link-snack1">Category</div>
              <div className="link-fresh1">
                2 MP Build-in Mic Fixed Bullet Network Camera
              </div>
              <div className="price">
                <div className="price-clr">
                  <p className="b1">₹2000</p>
                  <span className="div4">₹5000</span>
                  <div className="rating">
                    <Rating />
                  </div>
                </div>
              </div>
              <div className="compare">
                <div className="frame-parent2">
                  <input className="frame-input" type="checkbox" />
                  <b className="link-vegetables1">Compare</b>
                </div>
                <div className="addtocart">
                  <Button className="buy-now">Buy Now</Button>
                  <div className="badge">
                    <Badge>
                      <LocalGroceryStoreIcon />
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
            <div className="cards">
              <div className="likeandhot">
                <Like />
                <Button className="spanhot1">HOT</Button>
              </div>
              <div className="product-img">
                <img src={image1} alt="product-image" className="camera-1" />
              </div>
              <div className="link-snack1">Category</div>
              <div className="link-fresh1">
                2 MP Build-in Mic Fixed Bullet Network Camera
              </div>
              <div className="price">
                <div className="price-clr">
                  <p className="b1">₹2000</p>
                  <span className="div4">₹5000</span>
                  <div className="rating">
                    <Rating />
                  </div>
                </div>
              </div>
              <div className="compare">
                <div className="frame-parent2">
                  <input className="frame-input" type="checkbox" />
                  <b className="link-vegetables1">Compare</b>
                </div>
                <div className="addtocart">
                  <Button className="buy-now">Buy Now</Button>
                  <div className="badge">
                    <Badge>
                      <LocalGroceryStoreIcon />
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="cards-container">
            <div className="cards">
              <div className="likeandhot">
                <Like />
                <Button className="spanhot1">HOT</Button>
              </div>
              <div className="product-img">
                <img src={image1} alt="product-image" className="camera-1" />
              </div>
              <div className="link-snack1">Category</div>
              <div className="link-fresh1">
                2 MP Build-in Mic Fixed Bullet Network Camera
              </div>
              <div className="price">
                <div className="price-clr">
                  <p className="b1">₹2000</p>
                  <span className="div4">₹5000</span>
                  <div className="rating">
                    <Rating />
                  </div>
                </div>
              </div>
              <div className="compare">
                <div className="frame-parent2">
                  <input className="frame-input" type="checkbox" />
                  <b className="link-vegetables1">Compare</b>
                </div>
                <div className="addtocart">
                  <Button className="buy-now">Buy Now</Button>
                  <div className="badge">
                    <Badge>
                      <LocalGroceryStoreIcon />
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
            <div className="cards">
              <div className="likeandhot">
                <Like />
                <Button className="spanhot1">HOT</Button>
              </div>
              <div className="product-img">
                <img src={image1} alt="product-image" className="camera-1" />
              </div>
              <div className="link-snack1">Category</div>
              <div className="link-fresh1">
                2 MP Build-in Mic Fixed Bullet Network Camera
              </div>
              <div className="price">
                <div className="price-clr">
                  <p className="b1">₹2000</p>
                  <span className="div4">₹5000</span>
                  <div className="rating">
                    <Rating />
                  </div>
                </div>
              </div>
              <div className="compare">
                <div className="frame-parent2">
                  <input className="frame-input" type="checkbox" />
                  <b className="link-vegetables1">Compare</b>
                </div>
                <div className="addtocart">
                  <Button className="buy-now">Buy Now</Button>
                  <div className="badge">
                    <Badge>
                      <LocalGroceryStoreIcon />
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
            <div className="cards">
              <div className="likeandhot">
                <Like />
                <Button className="spanhot1">HOT</Button>
              </div>
              <div className="product-img">
                <img src={image1} alt="product-image" className="camera-1" />
              </div>
              <div className="link-snack1">Category</div>
              <div className="link-fresh1">
                2 MP Build-in Mic Fixed Bullet Network Camera
              </div>
              <div className="price">
                <div className="price-clr">
                  <p className="b1">₹2000</p>
                  <span className="div4">₹5000</span>
                  <div className="rating">
                    <Rating />
                  </div>
                </div>
              </div>
              <div className="compare">
                <div className="frame-parent2">
                  <input className="frame-input" type="checkbox" />
                  <b className="link-vegetables1">Compare</b>
                </div>
                <div className="addtocart">
                  <Button className="buy-now">Buy Now</Button>
                  <div className="badge">
                    <Badge>
                      <LocalGroceryStoreIcon />
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
            <div className="cards">
              <div className="likeandhot">
                <Like />
                <Button className="spanhot1">HOT</Button>
              </div>
              <div className="product-img">
                <img src={image1} alt="product-image" className="camera-1" />
              </div>
              <div className="link-snack1">Category</div>
              <div className="link-fresh1">
                2 MP Build-in Mic Fixed Bullet Network Camera
              </div>
              <div className="price">
                <div className="price-clr">
                  <p className="b1">₹2000</p>
                  <span className="div4">₹5000</span>
                  <div className="rating">
                    <Rating />
                  </div>
                </div>
              </div>
              <div className="compare">
                <div className="frame-parent2">
                  <input className="frame-input" type="checkbox" />
                  <b className="link-vegetables1">Compare</b>
                </div>
                <div className="addtocart">
                  <Button className="buy-now">Buy Now</Button>
                  <div className="badge">
                    <Badge>
                      <LocalGroceryStoreIcon />
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="cards-container">
            <div className="cards">
              <div className="likeandhot">
                <Like />
                <Button className="spanhot1">HOT</Button>
              </div>
              <div className="product-img">
                <img src={image1} alt="product-image" className="camera-1" />
              </div>
              <div className="link-snack1">Category</div>
              <div className="link-fresh1">
                2 MP Build-in Mic Fixed Bullet Network Camera
              </div>
              <div className="price">
                <div className="price-clr">
                  <p className="b1">₹2000</p>
                  <span className="div4">₹5000</span>
                  <div className="rating">
                    <Rating />
                  </div>
                </div>
              </div>
              <div className="compare">
                <div className="frame-parent2">
                  <input className="frame-input" type="checkbox" />
                  <b className="link-vegetables1">Compare</b>
                </div>
                <div className="addtocart">
                  <Button className="buy-now">Buy Now</Button>
                  <div className="badge">
                    <Badge>
                      <LocalGroceryStoreIcon />
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
            <div className="cards">
              <div className="likeandhot">
                <Like />
                <Button className="spanhot1">HOT</Button>
              </div>
              <div className="product-img">
                <img src={image1} alt="product-image" className="camera-1" />
              </div>
              <div className="link-snack1">Category</div>
              <div className="link-fresh1">
                2 MP Build-in Mic Fixed Bullet Network Camera
              </div>
              <div className="price">
                <div className="price-clr">
                  <p className="b1">₹2000</p>
                  <span className="div4">₹5000</span>
                  <div className="rating">
                    <Rating />
                  </div>
                </div>
              </div>
              <div className="compare">
                <div className="frame-parent2">
                  <input className="frame-input" type="checkbox" />
                  <b className="link-vegetables1">Compare</b>
                </div>
                <div className="addtocart">
                  <Button className="buy-now">Buy Now</Button>
                  <div className="badge">
                    <Badge>
                      <LocalGroceryStoreIcon />
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
            <div className="cards">
              <div className="likeandhot">
                <Like />
                <Button className="spanhot1">HOT</Button>
              </div>
              <div className="product-img">
                <img src={image1} alt="product-image" className="camera-1" />
              </div>
              <div className="link-snack1">Category</div>
              <div className="link-fresh1">
                2 MP Build-in Mic Fixed Bullet Network Camera
              </div>
              <div className="price">
                <div className="price-clr">
                  <p className="b1">₹2000</p>
                  <span className="div4">₹5000</span>
                  <div className="rating">
                    <Rating />
                  </div>
                </div>
              </div>
              <div className="compare">
                <div className="frame-parent2">
                  <input className="frame-input" type="checkbox" />
                  <b className="link-vegetables1">Compare</b>
                </div>
                <div className="addtocart">
                  <Button className="buy-now">Buy Now</Button>
                  <div className="badge">
                    <Badge>
                      <LocalGroceryStoreIcon />
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
            <div className="cards">
              <div className="likeandhot">
                <Like />
                <Button className="spanhot1">HOT</Button>
              </div>
              <div className="product-img">
                <img src={image1} alt="product-image" className="camera-1" />
              </div>
              <div className="link-snack1">Category</div>
              <div className="link-fresh1">
                2 MP Build-in Mic Fixed Bullet Network Camera
              </div>
              <div className="price">
                <div className="price-clr">
                  <p className="b1">₹2000</p>
                  <span className="div4">₹5000</span>
                  <div className="rating">
                    <Rating />
                  </div>
                </div>
              </div>
              <div className="compare">
                <div className="frame-parent2">
                  <input className="frame-input" type="checkbox" />
                  <b className="link-vegetables1">Compare</b>
                </div>
                <div className="addtocart">
                  <Button className="buy-now">Buy Now</Button>
                  <div className="badge">
                    <Badge>
                      <LocalGroceryStoreIcon />
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Productlist;
