import React from "react";
import "./Productlist.css";
import Grid from "@mui/material/Grid";
import { Button, IconButton } from "@mui/material";
import Badge from "@mui/material/Badge";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import Like from "./Like";
import Rating from "../Productlist2/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import image1 from "../../image/4.png";
import SideBar from "../SideBar/SideBar";   
import Typography from '@mui/material/Typography';
import Header from '../Header/Header'
import Footer from '../Footer/Footer'




function Productlist() {
  return (
    <div className="Productlist">
      <div>
        <Header />
      </div>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div className="top-panel">
          <div className="cctv-camera">
            <div className="cctv-font">CCTV Camera</div>
          </div>
          <div className="top-left">
            <Typography>
                {/* <ul className="top-right-list">
                    <li><span className="top-right">HOME</span></li>
                    <li><span className="top-right">SMART CAMERAS</span></li>
                    <li><span className="top-right">IP CAMERAS</span></li>
                    <li><span className="top-right">VALUE EXPRESS IP CAMERAS</span></li>
                </ul> */}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid container spacing={2} sx={{ justifyContent: "center" }}>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          <div className="leftside">
            <div className="sidebar">
              <SideBar />
            </div>
            <div className="subseries"></div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
          <div>
            <div className="right-list">
              <div className="cards-4">
                <div className="hot-image">
                  <div className="hot">
                    <Button className="spanhot1">NEW</Button>
                  </div>
                  <img src={image1} alt="product-image" className="camera-1" />
                </div>
                <div className="list">
                  <div className="link-fresh1">
                    2 MP Build-in Mic Fixed Bullet Network Camera
                  </div>
                  <div className="price">
                    <div className="price-clr">
                      <p className="b1">₹2000</p>
                      <span className="div4">₹5000</span>
                    </div>
                  </div>
                  <div className="para">
                    <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not on ly five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. I Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not on ly five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. I
                    </p>
                  </div>
                  <div className="compare-cart">
                    <div className="compare-rate">
                      <div className="frame-parent2">
                          <input className="frame-input" type="checkbox" />
                          <b className="link-vegetables1">Compare</b>
                      </div>
                      <div className="rate">
                        <Rating />
                      </div>
                    </div>
                      <div className="addtocart">
                        <Button className="buy-now">Buy Now</Button>
                        <div className="badge">
                          <Badge>
                            <LocalGroceryStoreIcon />
                          </Badge>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-list">
              <div className="cards-4">
                <div className="hot-image">
                  <div className="hot">
                    <Button className="spanhot1">NEW</Button>
                  </div>
                  <img src={image1} alt="product-image" className="camera-1" />
                </div>
                <div className="list">
                  <div className="link-fresh1">
                    2 MP Build-in Mic Fixed Bullet Network Camera
                  </div>
                  <div className="price">
                    <div className="price-clr">
                      <p className="b1">₹2000</p>
                      <span className="div4">₹5000</span>
                    </div>
                  </div>
                  <div className="para">
                    <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not on ly five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. I Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not on ly five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. I
                    </p>
                  </div>
                  <div className="compare-cart">
                    <div className="compare-rate">
                      <div className="frame-parent2">
                          <input className="frame-input" type="checkbox" />
                          <b className="link-vegetables1">Compare</b>
                      </div>
                      <div className="rate">
                        <Rating />
                      </div>
                    </div>
                      <div className="addtocart">
                        <Button className="buy-now">Buy Now</Button>
                        <div className="badge">
                          <Badge>
                            <LocalGroceryStoreIcon />
                          </Badge>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-list">
              <div className="cards-4">
                <div className="hot-image">
                  <div className="hot">
                    <Button className="spanhot1">NEW</Button>
                  </div>
                  <img src={image1} alt="product-image" className="camera-1" />
                </div>
                <div className="list">
                  <div className="link-fresh1">
                    2 MP Build-in Mic Fixed Bullet Network Camera
                  </div>
                  <div className="price">
                    <div className="price-clr">
                      <p className="b1">₹2000</p>
                      <span className="div4">₹5000</span>
                    </div>
                  </div>
                  <div className="para">
                    <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not on ly five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. I Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not on ly five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. I
                    </p>
                  </div>
                  <div className="compare-cart">
                    <div className="compare-rate">
                      <div className="frame-parent2">
                          <input className="frame-input" type="checkbox" />
                          <b className="link-vegetables1">Compare</b>
                      </div>
                      <div className="rate">
                        <Rating />
                      </div>
                    </div>
                      <div className="addtocart">
                        <Button className="buy-now">Buy Now</Button>
                        <div className="badge">
                          <Badge>
                            <LocalGroceryStoreIcon />
                          </Badge>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-list">
              <div className="cards-4">
                <div className="hot-image">
                  <div className="hot">
                    <Button className="spanhot1">NEW</Button>
                  </div>
                  <img src={image1} alt="product-image" className="camera-1" />
                </div>
                <div className="list">
                  <div className="link-fresh1">
                    2 MP Build-in Mic Fixed Bullet Network Camera
                  </div>
                  <div className="price">
                    <div className="price-clr">
                      <p className="b1">₹2000</p>
                      <span className="div4">₹5000</span>
                    </div>
                  </div>
                  <div className="para">
                    <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not on ly five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. I Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not on ly five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. I
                    </p>
                  </div>
                  <div className="compare-cart">
                    <div className="compare-rate">
                      <div className="frame-parent2">
                          <input className="frame-input" type="checkbox" />
                          <b className="link-vegetables1">Compare</b>
                      </div>
                      <div className="rate">
                        <Rating />
                      </div>
                    </div>
                      <div className="addtocart">
                        <Button className="buy-now">Buy Now</Button>
                        <div className="badge">
                          <Badge>
                            <LocalGroceryStoreIcon />
                          </Badge>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-list">
              <div className="cards-4">
                <div className="hot-image">
                  <div className="hot">
                    <Button className="spanhot1">NEW</Button>
                  </div>
                  <img src={image1} alt="product-image" className="camera-1" />
                </div>
                <div className="list">
                  <div className="link-fresh1">
                    2 MP Build-in Mic Fixed Bullet Network Camera
                  </div>
                  <div className="price">
                    <div className="price-clr">
                      <p className="b1">₹2000</p>
                      <span className="div4">₹5000</span>
                    </div>
                  </div>
                  <div className="para">
                    <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not on ly five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. I Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not on ly five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. I
                    </p>
                  </div>
                  <div className="compare-cart">
                    <div className="compare-rate">
                      <div className="frame-parent2">
                          <input className="frame-input" type="checkbox" />
                          <b className="link-vegetables1">Compare</b>
                      </div>
                      <div className="rate">
                        <Rating />
                      </div>
                    </div>
                      <div className="addtocart">
                        <Button className="buy-now">Buy Now</Button>
                        <div className="badge">
                          <Badge>
                            <LocalGroceryStoreIcon />
                          </Badge>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-list">
              <div className="cards-4">
                <div className="hot-image">
                  <div className="hot">
                    <Button className="spanhot1">NEW</Button>
                  </div>
                  <img src={image1} alt="product-image" className="camera-1" />
                </div>
                <div className="list">
                  <div className="link-fresh1">
                    2 MP Build-in Mic Fixed Bullet Network Camera
                  </div>
                  <div className="price">
                    <div className="price-clr">
                      <p className="b1">₹2000</p>
                      <span className="div4">₹5000</span>
                    </div>
                  </div>
                  <div className="para">
                    <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not on ly five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. I Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not on ly five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. I
                    </p>
                  </div>
                  <div className="compare-cart">
                    <div className="compare-rate">
                      <div className="frame-parent2">
                          <input className="frame-input" type="checkbox" />
                          <b className="link-vegetables1">Compare</b>
                      </div>
                      <div className="rate">
                        <Rating />
                      </div>
                    </div>
                      <div className="addtocart">
                        <Button className="buy-now">Buy Now</Button>
                        <div className="badge">
                          <Badge>
                            <LocalGroceryStoreIcon />
                          </Badge>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </Grid>
      </Grid>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Productlist;
