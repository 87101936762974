import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './SideBar.css'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';


const Sidebar = () => {

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className='sidebar-page'>

            <Box sx={{ display: 'flex' }} >
                <Grid container spacing={2} sx={{ justifyContent: 'center', padding: '0 24px' }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='left-border'>

                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className='acc-summary'
                            >
                                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                                    <div className='typography'>
                                        Subseries                                      
                                    </div>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className='acc-detail'>
                                <Typography>
                                    <ul>
                                        {/* <li><img src={camera} alt='camera' /><span>Network Camera <KeyboardArrowRightIcon /></span></li>
                                        <li><img src={network} alt='network' /><span>Network Video Recorder (NVR) <KeyboardArrowRightIcon /></span></li>
                                        <li><img src={ip} alt='ip' /><span>IP and Analog Cameras <KeyboardArrowRightIcon /></span></li>
                                        <li><img src={analog} alt='analog' /><span>Analog HD Camera <KeyboardArrowRightIcon /></span></li>
                                        <li><img src={cup} alt='cup' /><span>Speed Dome(PTZ) <KeyboardArrowRightIcon /></span></li>
                                        <li><img src={snowflake} alt='snowflake' /><span>Video Door Phones <KeyboardArrowRightIcon /></span></li>
                                        <li><img src={bar} alt='bar' /><span>Time & Attendance <KeyboardArrowRightIcon /></span></li>
                                        <li><img src={wheat} alt='wheat' /><span>Mobile Surveillance <KeyboardArrowRightIcon /></span></li>
                                        <li><img src={scoop} alt='scoop' /><span>Beyond Video <KeyboardArrowRightIcon /></span></li>
                                        <li><img src={toilet} alt='toilet' /><span>Video Wall and Controller <KeyboardArrowRightIcon /></span></li>
                                        <li><img src={babyboy} alt='babyboy' /><span>Software <KeyboardArrowRightIcon /></span></li> */}

                                        <li>
                                            <div className="subseries1">
                                                <input className="checkbox" type="checkbox" />
                                                <b className="checkbox-1">Value Series with MD 2.0</b>
                                            </div>
                                            <div className="subseries1">
                                                <input className="checkbox" type="checkbox" />
                                                <b className="checkbox-1">Value Series Essential</b>
                                            </div>
                                            <div className="subseries1">
                                                <input className="checkbox" type="checkbox" />
                                                <b className="checkbox-1">Snack & Spice</b>  
                                            </div>
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className='acc-summary'
                            >
                                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                                    <div className='typography'>
                                        Filter By Price                                        
                                    </div>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className='acc-detail'>
                                <Typography>
                                    <ul>
                                        
                                        <li>
                                            <div>
                                                <input type='range' className='range'/>
                                            </div>
                                            <div className='fliter-button'>
                                                <Button className="fliter">Fliter</Button>
                                            </div>
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className='acc-summary'
                            >
                                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                                    <div className='typography'>
                                    Case Type                                       
                                    </div>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className='acc-detail'>
                                <Typography>
                                    <ul>
                                        
                                        <li>
                                            <div className="subseries1">
                                                <input className="checkbox" type="checkbox" />
                                                <b className="checkbox-1">Bullet</b>
                                            </div>
                                            <div className="subseries1">
                                                <input className="checkbox" type="checkbox" />
                                                <b className="checkbox-1">Dome</b>
                                            </div>
                                            <div className="subseries1">
                                                <input className="checkbox" type="checkbox" />
                                                <b className="checkbox-1">Turret</b>
                                            </div>
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className='acc-summary'
                            >
                                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                                    <div className='typography'>
                                    Resolution                                       
                                    </div>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className='acc-detail'>
                                <Typography>
                                    <ul>
                                        
                                        <li>
                                            <div className="subseries1">
                                                <input className="checkbox" type="checkbox" />
                                                <b className="checkbox-1">2 MP</b>
                                            </div>
                                            <div className="subseries1">
                                                <input className="checkbox" type="checkbox" />
                                                <b className="checkbox-1">4 MP</b>
                                            </div>
                                            <div className="subseries1">
                                                <input className="checkbox" type="checkbox" />
                                                <b className="checkbox-1">8 MP</b>
                                            </div>
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className='acc-summary'
                            >
                                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                                    <div className='typography'>
                                    Lens Type                                       
                                    </div>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className='acc-detail'>
                                <Typography>
                                    <ul>
                                        
                                        <li>

                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className='acc-summary'
                            >
                                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                                    <div className='typography'>
                                    Low-Light Imaging                                        
                                    </div>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className='acc-detail'>
                                <Typography>
                                    <ul>
                                        
                                        <li>
                                            
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className='acc-summary'
                            >
                                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                                    <div className='typography'>
                                    Illumination Distance                                       
                                    </div>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className='acc-detail'>
                                <Typography>
                                    <ul>
                                        
                                        <li>
                                           
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className='acc-summary'
                            >
                                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                                    <div className='typography'>
                                    Environmental Protection                                       
                                    </div>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className='acc-detail'>
                                <Typography>
                                    <ul>
                                        
                                        <li>
                                           
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className='acc-summary'
                            >
                                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                                    <div className='typography'>
                                    Power Supply                                        
                                    </div>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className='acc-detail'>
                                <Typography>
                                    <ul>
                                        
                                        <li>
                                            
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Box>


        </div>
    )
}

export default Sidebar
