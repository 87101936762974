import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Grid from "@mui/material/Grid";

const Mycart = () => {
  return (
    <div className="framepage">
        <div>
            <Header />
        </div>
        <div className='frameleftandright'>
            <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                <div>    
                    fnfgf
                </div>
                </Grid>     
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <div>   
                        dfgfgf 
                    </div> 
                </Grid>
            </Grid>
                <div>

                </div>

            </div>
            <div>
                <Footer />
            </div>
        </div>
  )
}

export default Mycart