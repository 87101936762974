import React from 'react'
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './Header.css'
import { NavLink } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom'
import logo from "../../image/logo.svg"
import loginicon from "../../image/login-icon.svg"
import brandicon from "../../image/brand-icon.svg"
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import BasicExample from '../Dropdown/Dropdown';


const drawerWidth = 240;

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '6px',
    backgroundColor: '#F3F4F6',
    '&:hover': {
        backgroundColor: '#F3F4F6',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '0',
    right: '0',
    background: '#F53E32',
    borderRadius: '0 6px 6px 0',
}));


const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: '#000',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 0, 1, 1),
        // vertical padding + font size from searchIcon
        paddingRight: `calc(1em + ${theme.spacing(1)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '60ch',
        },
    },
}));

const Header = () => {

    // const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <div className='top-h-stack-respo'>
                <Link to='/Loginpage' className={({ isActive }) => (isActive ? 'active' : 'top-h-nav')} ><img src={loginicon} alt='loginicon' />Login</Link>
                <Link to='/login' className={({ isActive }) => (isActive ? 'active' : 'top-h-nav')} ><img src={brandicon} alt='loginicon' />Brand Store</Link>
                <Badge badgeContent={1}>
                    <FavoriteBorderIcon />
                </Badge>
                <Badge badgeContent={1}>
                    <LocalGroceryStoreIcon />
                </Badge>
            </div>
        </Box >
    );


    return (
        <div className='header-page'>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar component="nav" className='nav-top nav-top-position-relative'>
                    <Toolbar sx={{ justifyContent: 'space-between' }} className='body-contain'>

                        <div sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                            className='topheadlogo' >
                            <Link to="/" className='Credenze'>Credenze</Link>

                            
                            <span><BasicExample /></span>
                            <Search>
                                <StyledInputBase
                                    placeholder="Search for Products & Categories"
                                    inputProps={{ 'aria-label': 'search' }}
                                />

                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                            </Search>
                        </div>
                        <Box sx={{ display: { xs: 'none', sm: 'block' } }} >

                            {drawer}


                        </Box>

                        <IconButton
                            color="#000"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <nav className='nav-top-head'>
                    <Drawer
                        // container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        <div className='topheadlogo'><Link><img src={logo} alt='hlogo' /></Link> </div>
                        {drawer}


                    </Drawer>
                </nav>

            </Box>
        </div>
    )
}

export default Header