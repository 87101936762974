import React, { useState } from "react";
import { Button, IconButton } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";

const LikeButton = () => {
    const [isLiked, setIsLiked] = useState(false);

    const handleClick = () => {
        setIsLiked(!isLiked);
    };

    return (
        <IconButton
            onClick={handleClick}
            aria-label="Like"
            color={isLiked ? "primary" : "default"}
        >
            <FavoriteIcon />
        </IconButton>
    );
};

export default LikeButton;
