import React from "react";
import "./Framemain.css";
import Grid from "@mui/material/Grid";
import { Button, IconButton } from "@mui/material";
import Badge from "@mui/material/Badge";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import Rating from "../Productlist2/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import image1 from "../../image/4.png";
import SideBar from "../SideBar/SideBar";   
import Typography from '@mui/material/Typography';
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Rectanglemain from '../../image/Rectangle main.png'
import { CheckBox } from "@mui/icons-material";




function framemain() {


  return (
    <div className="framepage">
      <div>
        <Header />
      </div>
      <div className='frameleftandright'>
        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <div>    
                <div className="frame-left">
                    <div className="frame-leftmain">
                        <div>
                            <div className="rectanglemaincamera">
                                <img src={Rectanglemain} alt="Rectanglemain" className="Rectanglemain" />
                            </div> 
                            <div className="thumbnail-image">
                            <img src={Rectanglemain} alt="Rectanglemain" className="thumbnail" />
                            <img src={Rectanglemain} alt="Rectanglemain" className="thumbnail" />
                            <img src={Rectanglemain} alt="Rectanglemain" className="thumbnail" />
                            <img src={Rectanglemain} alt="Rectanglemain" className="thumbnail" />
                            </div>
                            <div>
                                <div className="benefits"><p>Credenze Benefits</p></div>
                                <div className="benefit-brance">
                                    <p className="benefit-brances">GST Invoice Available</p>
                                    <p className="benefit-brances">Secure Payments</p>
                                    <p className="benefit-brances"  >365 Days Help Desk</p>
                                </div>
                            </div>
                            <div>
                                <div className="benefits"><p>Credenze Benefits</p></div>
                                <div className="benefit-brance">
                                    <p className="benefit-brances">7 Days Brand Replacement</p>
                                    <p className="benefit-brances"> Missing Product</p>
                                    <p className="benefit-brances"  >Wrong Product</p>
                                </div>
                                <div><p className="benefit-brances"  >Damaged Product</p></div>
                            </div>
                        </div>
                        <div>
                            <div className="heading">
                                8MP Outdoor AI Surveillance Camera
                            </div>
                            <div>
                                <Rating />
                            </div>
                            <div>
                                <div><p className="colour-button">Colour</p></div>
                                <div className="black-white-button">
                                    <div><Button className="white-button">White</Button></div>
                                    <div><Button className="black-button">Black</Button></div>
                                </div>
                                <div><p className="pincode-para">Check Serviceability at your Pin code</p></div>
                                <div>
                                    <input className="pincode-box" placeholder="Enter Your Pin Code"></input>
                                    <Button className="check-pincode-button">check</Button>
                                </div>
                                <div className="replacement-warranty">
                                    <div className="replacement">
                                        <p>7 Days Brand Replacement</p>
                                    </div>
                                    <div className="warranty">
                                        <p>1Year Warranty</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="description-frame">
                                        <h3>Description</h3>
                                        <hr />
                                    </div>
                                    <div className="description-para">
                                        <p>
                                        The Surveillance Camera is a state-of-the-art CCTV solution designed to provide comprehensive security monitoring for both residential and commercial properties. With its advanced features and robust construction, this camera ensures reliable performance and peace of mind.
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div className="keyfeatures-frame">
                                        <h3>Key Features</h3>
                                        <div className="hr-frame">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="keyfeatures-para">
                                        <li className="li-keyfeatures">High Resolution</li>
                                        <li className="li-keyfeatures">Weatherproofing</li>
                                        <li className="li-keyfeatures">Infrared Night Vision</li>
                                        <li className="li-keyfeatures">Motion Detection</li>
                                        <li className="li-keyfeatures">Cloud Storage and Remote Access</li>
                                        <li className="li-keyfeatures">Tamper Detection</li>
                                        <li className="li-keyfeatures">Integration with Smart Home Systems</li>
                                    </div>
                                </div>
                                <div>
                                    <div className="keyfeatures-frame">
                                        <h3>Offers & Coupons (9)</h3>
                                        <hr />
                                    </div>
                                    <div className="flatcctv-main">
                                        <div className="flat-on-cctv">
                                            <p className="flat-para">Get Flat 100 OFF on CCTV Cameras| Min cart value Rs. 10000</p>
                                            <div>
                                                <Button className="CCTV100-button">CCTV100</Button>
                                            </div>
                                        </div>
                                        <hr  className="hr-flat"/>
                                        <div className="flat-on-cctv">
                                            <p className="flat-para">Get Flat 100 OFF on CCTV Cameras| Min cart value Rs. 10000</p>
                                            <div className="viewlink-main">
                                                <a href="#" className="view-link">View</a>
                                            </div>
                                        </div>
                                        <hr  className="hr-flat"/>
                                        <div className="flat-on-cctv">
                                            <p className="flat-para">Get Flat 100 OFF on CCTV Cameras| Min cart value Rs. 10000</p>
                                            <div className="viewlink-main">
                                                <a href="#" className="view-link">View</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>        
                    </div> 
                </div>
            </div>
            </Grid>     
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <div>   
                <div className="frame-right">
                    <div className="">
                        <div className="price-clr">
                            <div>
                                <p className="b1">₹2000</p>
                            </div>
                        </div>
                        <div className="old-price">
                            <div>
                                <p>(GST inclusive price)</p>
                            </div>
                            <div>
                                <p className="b1">₹5000</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="number-button">
                        <div>
                            <input type="number" className="number-selection"></input>
                        </div>
                        <div className="buynow-button-frame">
                            <Button>BUY NOW</Button>
                        </div>
                        <div className="addtocart-button-frame">
                            <Button className="addtocart-color">ADD TO CART</Button>
                        </div>
                    </div>
                    <hr className="checkbox-hr"/>
                    <div>
                        <div className="essential-heading">
                            <h3>Essential Combo</h3>
                        </div>
                        <hr className="checkbox-hr"/>
                        <div className="checkbox-image-main">
                            <input type='checkbox' className="checkbox-main"></input>
                            <img src ={Rectanglemain} alt="Rectanglemain" className="checkbox-Rectanglemain"></img>
                            <div className="checkbox-para">
                                <h5> 8MP Outdoor AI Surveillance Camera</h5>
                                <p>These combo offers cater to a range of customer preferences and requirements</p>
                            </div>
                        </div>
                        <hr  className="checkbox-hr"/>
                        <div className="checkbox-image-main">
                            <input type='checkbox' className="checkbox-main"></input>
                            <img src ={Rectanglemain} alt="Rectanglemain" className="checkbox-Rectanglemain"></img>
                            <div className="checkbox-para">
                                <h5> 8MP Outdoor AI Surveillance Camera</h5>
                                <p>These combo offers cater to a range of customer preferences and requirements</p>
                            </div>
                        </div>
                        <hr  className="checkbox-hr"/>
                        <div className="checkbox-image-main">
                            <input type='checkbox' className="checkbox-main"></input>
                            <img src ={Rectanglemain} alt="Rectanglemain" className="checkbox-Rectanglemain"></img>
                            <div className="checkbox-para">
                                <h5> 8MP Outdoor AI Surveillance Camera</h5>
                                <p>These combo offers cater to a range of customer preferences and requirements</p>
                            </div>
                        </div>
                        <hr  className="checkbox-hr"/>
                        <div className="checkbox-image-main">
                            <input type='checkbox' className="checkbox-main"></input>
                            <img src ={Rectanglemain} alt="Rectanglemain" className="checkbox-Rectanglemain"></img>
                            <div className="checkbox-para">
                                <h5> 8MP Outdoor AI Surveillance Camera</h5>
                                <p>These combo offers cater to a range of customer preferences and requirements</p>
                            </div>
                        </div>
                        <hr  className="checkbox-hr"/>
                        <div className="checkbox-image-main">
                            <input type='checkbox' className="checkbox-main"></input>
                            <img src ={Rectanglemain} alt="Rectanglemain" className="checkbox-Rectanglemain"></img>
                            <div className="checkbox-para">
                                <h5> 8MP Outdoor AI Surveillance Camera</h5>
                                <p>These combo offers cater to a range of customer preferences and requirements</p>
                            </div>
                        </div>
                        <hr  className="checkbox-hr"/>
                    </div>
                    <div>
                        <div className="essential-heading">
                            <h3>Essential Combo</h3>
                        </div>
                        <hr className="checkbox-hr"/>
                        <div className="checkbox-image-main">
                            <input type='checkbox' className="checkbox-main"></input>
                            <img src ={Rectanglemain} alt="Rectanglemain" className="checkbox-Rectanglemain"></img>
                            <div className="checkbox-para">
                                <h5> 8MP Outdoor AI Surveillance Camera</h5>
                                <p>These combo offers cater to a range of customer preferences and requirements</p>
                            </div>
                        </div>
                        <hr  className="checkbox-hr"/>
                        <div className="checkbox-image-main">
                            <input type='checkbox' className="checkbox-main"></input>
                            <img src ={Rectanglemain} alt="Rectanglemain" className="checkbox-Rectanglemain"></img>
                            <div className="checkbox-para">
                                <h5> 8MP Outdoor AI Surveillance Camera</h5>
                                <p>These combo offers cater to a range of customer preferences and requirements</p>
                            </div>
                        </div>
                        <hr  className="checkbox-hr"/>
                        <div className="checkbox-image-main">
                            <input type='checkbox' className="checkbox-main"></input>
                            <img src ={Rectanglemain} alt="Rectanglemain" className="checkbox-Rectanglemain"></img>
                            <div className="checkbox-para">
                                <h5> 8MP Outdoor AI Surveillance Camera</h5>
                                <p>These combo offers cater to a range of customer preferences and requirements</p>
                            </div>
                        </div>
                        <hr  className="checkbox-hr"/>
                    </div>
                </div>
                </div> 
            </Grid>
        </Grid>
            <div>
                <div className="heading-down-main">
                    <h3 className="heading-down">Description</h3>
                    <h3 className="heading-down">Specification</h3>
                    <h3 className="heading-down">Instruction</h3>
                </div>
                <div className="description-para">
                    <p>
                        The XYZ Surveillance Camera is a state-of-the-art CCTV solution designed to provide comprehensive security monitoring for both residential and commercial properties. With its advanced features and robust construction, this camera ensures reliable performance and peace of mind.The XYZ Surveillance Camera is a state-of-the-art CCTV solution designed to provide comprehensive security monitoring for both residential and commercial properties. With its advanced features and robust construction, this camera ensures reliable performance and peace of mind.
                    </p>
                </div>
                <div>
                    <div className="packaging">
                        <h1>Packaging & Delivery</h1>
                        <hr />
                    </div>
                    <div className="description-para">
                        <p>Less lion goodness that euphemistically robin expeditiously bluebird smugly scratched far while thus cackled sheepishly rigid after due one  assenting regarding censorious while occasional or this more crane went more as this less much amid overhung anathematic because much held one exuberantly sheep goodness so where rat wry well concomitantly.</p><br />
                        <p>Scallop or far crud plain remarkably far by thus far iguana lewd precociously and and less rattlesnake contrary caustic wow this near alas and  next and pled the yikes articulate about as less cackled dalmatian in much less well jeering for the thanks blindly sentimental whimpered less across objectively fanciful grimaced wildly some wow and rose jeepers outgrew lugubrious luridly irrationally attractively dachshund.</p>
                    </div>
                </div>
                <div className="packaging">
                    <h1>Key Features</h1>
                    <hr />
                </div>
                <div className="keyfeatures-para">
                    <ol>
                        <li className="li-keyfeatures">High Definition Video: Capture crisp and clear footage in stunning high definition, allowing for accurate identification of subjects and incidents</li>
                        <li className="li-keyfeatures">Night Vision: Equipped with infrared LEDs, this camera delivers exceptional night vision capabilities, ensuring round-the-clock surveillance even in low-light conditions.</li>
                        <li className="li-keyfeatures">Wide-Angle Lens: The wide-angle lens provides extensive coverage, reducing blind spots and maximizing surveillance area.</li>
                        <li className="li-keyfeatures">Motion Detection: Detects motion within its field of view and triggers instant alerts, keeping you informed of any suspicious activity in real-time.</li>
                        <li className="li-keyfeatures">Weatherproof Design: Built to withstand harsh weather conditions, this camera is suitable for both indoor and outdoor installations, ensuring reliable performance in any environment.</li>
                        <li className="li-keyfeatures">Remote Viewing: Access live footage and recordings from anywhere using a smartphone, tablet, or computer, providing convenient monitoring on the go.</li>
                        <li className="li-keyfeatures">Easy Installation: Simple to set up and configure, allowing for hassle-free installation by users of all skill levels.</li>
                        <li className="li-keyfeatures">Secure Storage: Supports various storage options, including local storage via SD card and cloud storage, ensuring secure retention of footage for future reference.</li>

                    </ol>
                </div>
                <div className="keyfeatures-frame">
                    <h3>Technical Specifications</h3>
                    <div className="hr-frame">
                        <hr />
                    </div>
                </div>
                <div className="keyfeatures-para">
                    <li className="li-keyfeatures">High Resolution</li>
                    <li className="li-keyfeatures">Weatherproofing</li>
                    <li className="li-keyfeatures">Infrared Night Vision</li>
                    <li className="li-keyfeatures">Motion Detection</li>
                    <li className="li-keyfeatures">Cloud Storage and Remote Access</li>
                    <li className="li-keyfeatures">Tamper Detection</li>
                    <li className="li-keyfeatures">Integration with Smart Home Systems</li>
                </div>
                <div className="keyfeatures-frame">
                    <h3>Download Center</h3>
                    <div className="hr-frame">
                        <hr />
                    </div>
                </div>
                <div className="keyfeatures-para">
                    <li className="li-keyfeatures">High Resolution</li>
                    <li className="li-keyfeatures">Weatherproofing</li>
                    <li className="li-keyfeatures">Infrared Night Vision</li>
                    <li className="li-keyfeatures">Motion Detection</li>
                </div>
            </div>

        </div>
        <div>
            <Footer />
        </div>
    </div>
  );
}

export default framemain;
