import React, { useState } from "react";
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import camera from '../../image/camera.svg'
import carofull from '../../image/caro-full.png'
import smallcaro1 from '../../image/small-caro1.png'
import smallcaro2 from '../../image/small-caro2.png'
import smallcaro3 from '../../image/small-caro3.png'
import smallcaro4 from '../../image/small-caro4.png'
import cameracard from '../../image/camera.png'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import './Homepage.css'
import bannerimg from '../../image/banner-img.png'
import { Button, IconButton } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import product from "../../image/product-img.png";
import StarIcon from "@mui/icons-material/Star";
import Badge from '@mui/material/Badge';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import Rating from '../Homepage/Rating'
import Like from '../Homepage/Like'
import tabpanel1 from "../../image/4.png";
import tabpanel2 from "../../image/2.png";
import tabpanel3 from "../../image/5.png";
import tabpanel46 from "../../image/6.png";
import tabpanel5 from "../../image/7.png";
import shop1 from "../../image/shop1.png";
import shop2 from "../../image/shop2.png";
import fotertop1 from "../../image/fotertop1.png";
import fotertop2 from "../../image/fotertop2.png";
import fotertop3 from "../../image/fotertop3.png";
import { Link } from "react-router-dom";

const drawerWidth = 240;

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Homepage = () => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    const labels = {
        0.5: "0.5",
        1: "1",
        1.5: "1.5",
        2: "2",
        2.5: "2.5",
        3: "3",
        3.5: "3.5",
        4: "4",
        4.5: "4.5",
        5: "5",
    };

    function getLabelText(value) {
        return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
    }


    const [rate, setRate] = React.useState(2);
    const [hover, setHover] = React.useState(-1);

    return (
        <div className='homepage'>
            <Box sx={{ display: 'flex' }} >
                <Grid container spacing={2} sx={{ justifyContent: 'center' }} >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Header />
                    </Grid>
                    <Grid container spacing={2} className='body-contain'>
                        <div className='banner-img'>
                            <img src={bannerimg} alt='banner-img' />
                        </div>

                        <div className="best-sellers">
                            <h2>Best Sellers</h2>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div id="card" className="card-box">
                                    <div className="cards">
                                        <div className="likeandhot">
                                            <Like />

                                            <Button className="spanhot1">HOT</Button>
                                        </div>
                                        <div className="product-img">
                                            <img src={product} alt="product-image" className="camera-1" />
                                        </div>

                                        {/* <img src={product} alt="product-image" className="camera-1" /> */}
                                        <div className="link-snack1">Category</div>
                                            <Link to="/productlist">Productcart</Link>
                                        <div className="link-fresh1">
                                            2 MP Build-in Mic Fixed Bullet Network Camera
                                        </div>
                                        <div className="price">
                                            <div className="price-clr">
                                                <p className="b1">₹2000</p>
                                                <span className="div4">₹5000</span>
                                            </div>

                                            <div className="rating">
                                                <Rating
                                                    name="hover-feedback"
                                                    value={rate}
                                                    precision={0.5}
                                                    getLabelText={getLabelText}
                                                    onChange={(event, newValue) => {
                                                        setRate(newValue);
                                                    }}
                                                    onChangeActive={(event, newHover) => {
                                                        setHover(newHover);
                                                    }}
                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                />
                                                {value !== null && (
                                                    <Box sx={{ ml: 1 }}>{labels[hover !== -1 ? hover : value]}</Box>
                                                )}
                                            </div>

                                        </div>
                                        <div className="compare">
                                            <div className="frame-parent2">
                                                <input className="frame-input" type="checkbox" />
                                                <b className="link-vegetables1">Compare</b>
                                            </div>
                                            <div className="addtocart">
                                                <Button className="buy-now">Buy Now</Button>
                                                <div className="badge">
                                                    <Badge >
                                                        <LocalGroceryStoreIcon />
                                                    </Badge>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="cards">
                                        <div className="likeandhot">
                                            <Like />

                                            <Button className="spanhot1">HOT</Button>
                                        </div>
                                        <div className="product-img">
                                            <img src={product} alt="product-image" className="camera-1" />
                                        </div>

                                        {/* <img src={product} alt="product-image" className="camera-1" /> */}
                                        <div className="link-snack1">Category</div>
                                        <div className="link-fresh1">
                                            2 MP Build-in Mic Fixed Bullet Network Camera
                                        </div>
                                        <div className="price">
                                            <div className="price-clr">
                                                <p className="b1">₹2000</p>
                                                <span className="div4">₹5000</span>
                                            </div>

                                            <div className="rating">
                                                <Rating
                                                    name="hover-feedback"
                                                    value={rate}
                                                    precision={0.5}
                                                    getLabelText={getLabelText}
                                                    onChange={(event, newValue) => {
                                                        setRate(newValue);
                                                    }}
                                                    onChangeActive={(event, newHover) => {
                                                        setHover(newHover);
                                                    }}
                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                />
                                                {value !== null && (
                                                    <Box sx={{ ml: 1 }}>{labels[hover !== -1 ? hover : value]}</Box>
                                                )}
                                            </div>

                                        </div>
                                        <div className="compare">
                                            <div className="frame-parent2">
                                                <input className="frame-input" type="checkbox" />
                                                <b className="link-vegetables1">Compare</b>
                                            </div>
                                            <div className="addtocart">
                                                <Button className="buy-now">Buy Now</Button>
                                                <div className="badge">
                                                    <Badge >
                                                        <LocalGroceryStoreIcon />
                                                    </Badge>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <div className="cards">
                                        <div className="likeandhot">
                                            <Like />

                                            <Button className="spanhot1">HOT</Button>
                                        </div>
                                        <div className="product-img">
                                            <img src={product} alt="product-image" className="camera-1" />
                                        </div>

                                        {/* <img src={product} alt="product-image" className="camera-1" /> */}
                                        <div className="link-snack1">Category</div>
                                        <div className="link-fresh1">
                                            2 MP Build-in Mic Fixed Bullet Network Camera
                                        </div>
                                        <div className="price">
                                            <div className="price-clr">
                                                <p className="b1">₹2000</p>
                                                <span className="div4">₹5000</span>
                                            </div>

                                            <div className="rating">
                                                <Rating
                                                    name="hover-feedback"
                                                    value={rate}
                                                    precision={0.5}
                                                    getLabelText={getLabelText}
                                                    onChange={(event, newValue) => {
                                                        setRate(newValue);
                                                    }}
                                                    onChangeActive={(event, newHover) => {
                                                        setHover(newHover);
                                                    }}
                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                />
                                                {value !== null && (
                                                    <Box sx={{ ml: 1 }}>{labels[hover !== -1 ? hover : value]}</Box>
                                                )}
                                            </div>

                                        </div>
                                        <div className="compare">
                                            <div className="frame-parent2">
                                                <input className="frame-input" type="checkbox" />
                                                <b className="link-vegetables1">Compare</b>
                                            </div>
                                            <div className="addtocart">
                                                <Button className="buy-now">Buy Now</Button>
                                                <div className="badge">
                                                    <Badge >
                                                        <LocalGroceryStoreIcon />
                                                    </Badge>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <div className="cards">
                                        <div className="likeandhot">
                                            <Like />

                                            <Button className="spanhot1">HOT</Button>
                                        </div>
                                        <div className="product-img">
                                            <img src={product} alt="product-image" className="camera-1" />
                                        </div>

                                        {/* <img src={product} alt="product-image" className="camera-1" /> */}
                                        <div className="link-snack1">Category</div>
                                        <div className="link-fresh1">
                                            2 MP Build-in Mic Fixed Bullet Network Camera
                                        </div>
                                        <div className="price">
                                            <div className="price-clr">
                                                <p className="b1">₹2000</p>
                                                <span className="div4">₹5000</span>
                                            </div>

                                            <div className="rating">
                                                <Rating
                                                    name="hover-feedback"
                                                    value={rate}
                                                    precision={0.5}
                                                    getLabelText={getLabelText}
                                                    onChange={(event, newValue) => {
                                                        setRate(newValue);
                                                    }}
                                                    onChangeActive={(event, newHover) => {
                                                        setHover(newHover);
                                                    }}
                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                />
                                                {value !== null && (
                                                    <Box sx={{ ml: 1 }}>{labels[hover !== -1 ? hover : value]}</Box>
                                                )}
                                            </div>

                                        </div>
                                        <div className="compare">
                                            <div className="frame-parent2">
                                                <input className="frame-input" type="checkbox" />
                                                <b className="link-vegetables1">Compare</b>
                                            </div>
                                            <div className="addtocart">
                                                <Button className="buy-now">Buy Now</Button>
                                                <div className="badge">
                                                    <Badge >
                                                        <LocalGroceryStoreIcon />
                                                    </Badge>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <div className="cards">
                                        <div className="likeandhot">
                                            <Like />

                                            <Button className="spanhot1">HOT</Button>
                                        </div>
                                        <div className="product-img">
                                            <img src={product} alt="product-image" className="camera-1" />
                                        </div>

                                        {/* <img src={product} alt="product-image" className="camera-1" /> */}
                                        <div className="link-snack1">Category</div>
                                        <div className="link-fresh1">
                                            2 MP Build-in Mic Fixed Bullet Network Camera
                                        </div>
                                        <div className="price">
                                            <div className="price-clr">
                                                <p className="b1">₹2000</p>
                                                <span className="div4">₹5000</span>
                                            </div>

                                            <div className="rating">
                                                <Rating
                                                    name="hover-feedback"
                                                    value={rate}
                                                    precision={0.5}
                                                    getLabelText={getLabelText}
                                                    onChange={(event, newValue) => {
                                                        setRate(newValue);
                                                    }}
                                                    onChangeActive={(event, newHover) => {
                                                        setHover(newHover);
                                                    }}
                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                />
                                                {value !== null && (
                                                    <Box sx={{ ml: 1 }}>{labels[hover !== -1 ? hover : value]}</Box>
                                                )}
                                            </div>

                                        </div>
                                        <div className="compare">
                                            <div className="frame-parent2">
                                                <input className="frame-input" type="checkbox" />
                                                <b className="link-vegetables1">Compare</b>
                                            </div>
                                            <div className="addtocart">
                                                <Button className="buy-now">Buy Now</Button>
                                                <div className="badge">
                                                    <Badge >
                                                        <LocalGroceryStoreIcon />
                                                    </Badge>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>


                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className="cards-1main">

                                    <div className="cards-1">
                                        <h4>Deals <br />and offers</h4>
                                        <span>Simplify Access Elevate Security</span>


                                    </div>

                                    <div className="cards-1">
                                        <div className="tabpanel">
                                            <img src={tabpanel1} alt="camera" className="tabpanel" />
                                        </div>

                                        <p>Camera Name</p>
                                        <h6>-30%</h6>
                                    </div>
                                    <div className="cards-1">
                                        <div className="tabpanel">
                                            <img src={tabpanel2} alt="camera" className="tabpanel2" />
                                        </div>
                                        <p>Camera Name</p>
                                        <h6>-30%</h6>
                                    </div>
                                    <div className="cards-1">
                                        <div className="tabpanel">
                                            <img src={tabpanel3} alt="camera" className="tabpanel" />
                                        </div>
                                        <p>Camera Name</p>
                                        <h6>-30%</h6>
                                    </div>
                                    <div className="cards-1">
                                        <div className="tabpanel">
                                            <img src={tabpanel46} alt="camera" className="tabpanel" />
                                        </div>
                                        <p>Camera Name</p>
                                        <h6>-30%</h6>
                                    </div>
                                    <div className="cards-1">
                                        <div className="tabpanel">
                                            <img src={tabpanel5} alt="camera" className="tabpanel" />
                                        </div>
                                        <p>Camera Name</p>
                                        <h6>-30%</h6>
                                    </div>
                                    <div className="cards-1">
                                        <div className="tabpanel">
                                            <img src={tabpanel46} alt="camera" className="tabpanel" />
                                        </div>
                                        <p>Camera Name</p>
                                        <h6>-30%</h6>
                                    </div>
                                    <div className="cards-1">
                                        <div className="tabpanel">
                                            <img src={tabpanel1} alt="camera" className="tabpanel" />
                                        </div>
                                        <p>Camera Name</p>
                                        <h6>-30%</h6>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>


                        <div className="best-sellers">
                            <h2>Dome CCTV Cameras</h2>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div id="card" className="card-box">
                                    <div className="product-img">
                                        <img src={shop1} alt="product-image" className="shop1" />
                                    </div>

                                    <div className="cards">
                                        <div className="likeandhot">
                                            <Like />

                                            <Button className="spanhot1">HOT</Button>
                                        </div>
                                        <div className="product-img">
                                            <img src={product} alt="product-image" className="camera-1" />
                                        </div>

                                        {/* <img src={product} alt="product-image" className="camera-1" /> */}
                                        <div className="link-snack1">Category</div>
                                        <div className="link-fresh1">
                                            2 MP Build-in Mic Fixed Bullet Network Camera
                                        </div>
                                        <div className="price">
                                            <div className="price-clr">
                                                <p className="b1">₹2000</p>
                                                <span className="div4">₹5000</span>
                                            </div>

                                            <div className="rating">
                                                <Rating
                                                    name="hover-feedback"
                                                    value={rate}
                                                    precision={0.5}
                                                    getLabelText={getLabelText}
                                                    onChange={(event, newValue) => {
                                                        setRate(newValue);
                                                    }}
                                                    onChangeActive={(event, newHover) => {
                                                        setHover(newHover);
                                                    }}
                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                />
                                                {value !== null && (
                                                    <Box sx={{ ml: 1 }}>{labels[hover !== -1 ? hover : value]}</Box>
                                                )}
                                            </div>

                                        </div>
                                        <div className="compare">
                                            <div className="frame-parent2">
                                                <input className="frame-input" type="checkbox" />
                                                <b className="link-vegetables1">Compare</b>
                                            </div>
                                            <div className="addtocart">
                                                <Button className="buy-now">Buy Now</Button>
                                                <div className="badge">
                                                    <Badge >
                                                        <LocalGroceryStoreIcon />
                                                    </Badge>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <div className="cards">
                                        <div className="likeandhot">
                                            <Like />

                                            <Button className="spanhot1">HOT</Button>
                                        </div>
                                        <div className="product-img">
                                            <img src={product} alt="product-image" className="camera-1" />
                                        </div>

                                        {/* <img src={product} alt="product-image" className="camera-1" /> */}
                                        <div className="link-snack1">Category</div>
                                        <div className="link-fresh1">
                                            2 MP Build-in Mic Fixed Bullet Network Camera
                                        </div>
                                        <div className="price">
                                            <div className="price-clr">
                                                <p className="b1">₹2000</p>
                                                <span className="div4">₹5000</span>
                                            </div>

                                            <div className="rating">
                                                <Rating
                                                    name="hover-feedback"
                                                    value={rate}
                                                    precision={0.5}
                                                    getLabelText={getLabelText}
                                                    onChange={(event, newValue) => {
                                                        setRate(newValue);
                                                    }}
                                                    onChangeActive={(event, newHover) => {
                                                        setHover(newHover);
                                                    }}
                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                />
                                                {value !== null && (
                                                    <Box sx={{ ml: 1 }}>{labels[hover !== -1 ? hover : value]}</Box>
                                                )}
                                            </div>

                                        </div>
                                        <div className="compare">
                                            <div className="frame-parent2">
                                                <input className="frame-input" type="checkbox" />
                                                <b className="link-vegetables1">Compare</b>
                                            </div>
                                            <div className="addtocart">
                                                <Button className="buy-now">Buy Now</Button>
                                                <div className="badge">
                                                    <Badge >
                                                        <LocalGroceryStoreIcon />
                                                    </Badge>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <div className="cards">
                                        <div className="likeandhot">
                                            <Like />

                                            <Button className="spanhot1">HOT</Button>
                                        </div>
                                        <div className="product-img">
                                            <img src={product} alt="product-image" className="camera-1" />
                                        </div>

                                        {/* <img src={product} alt="product-image" className="camera-1" /> */}
                                        <div className="link-snack1">Category</div>
                                        <div className="link-fresh1">
                                            2 MP Build-in Mic Fixed Bullet Network Camera
                                        </div>
                                        <div className="price">
                                            <div className="price-clr">
                                                <p className="b1">₹2000</p>
                                                <span className="div4">₹5000</span>
                                            </div>

                                            <div className="rating">
                                                <Rating
                                                    name="hover-feedback"
                                                    value={rate}
                                                    precision={0.5}
                                                    getLabelText={getLabelText}
                                                    onChange={(event, newValue) => {
                                                        setRate(newValue);
                                                    }}
                                                    onChangeActive={(event, newHover) => {
                                                        setHover(newHover);
                                                    }}
                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                />
                                                {value !== null && (
                                                    <Box sx={{ ml: 1 }}>{labels[hover !== -1 ? hover : value]}</Box>
                                                )}
                                            </div>

                                        </div>
                                        <div className="compare">
                                            <div className="frame-parent2">
                                                <input className="frame-input" type="checkbox" />
                                                <b className="link-vegetables1">Compare</b>
                                            </div>
                                            <div className="addtocart">
                                                <Button className="buy-now">Buy Now</Button>
                                                <div className="badge">
                                                    <Badge >
                                                        <LocalGroceryStoreIcon />
                                                    </Badge>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <div className="cards">
                                        <div className="likeandhot">
                                            <Like />

                                            <Button className="spanhot1">HOT</Button>
                                        </div>
                                        <div className="product-img">
                                            <img src={product} alt="product-image" className="camera-1" />
                                        </div>

                                        {/* <img src={product} alt="product-image" className="camera-1" /> */}
                                        <div className="link-snack1">Category</div>
                                        <div className="link-fresh1">
                                            2 MP Build-in Mic Fixed Bullet Network Camera
                                        </div>
                                        <div className="price">
                                            <div className="price-clr">
                                                <p className="b1">₹2000</p>
                                                <span className="div4">₹5000</span>
                                            </div>

                                            <div className="rating">
                                                <Rating
                                                    name="hover-feedback"
                                                    value={rate}
                                                    precision={0.5}
                                                    getLabelText={getLabelText}
                                                    onChange={(event, newValue) => {
                                                        setRate(newValue);
                                                    }}
                                                    onChangeActive={(event, newHover) => {
                                                        setHover(newHover);
                                                    }}
                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                />
                                                {value !== null && (
                                                    <Box sx={{ ml: 1 }}>{labels[hover !== -1 ? hover : value]}</Box>
                                                )}
                                            </div>

                                        </div>
                                        <div className="compare">
                                            <div className="frame-parent2">
                                                <input className="frame-input" type="checkbox" />
                                                <b className="link-vegetables1">Compare</b>
                                            </div>
                                            <div className="addtocart">
                                                <Button className="buy-now">Buy Now</Button>
                                                <div className="badge">
                                                    <Badge >
                                                        <LocalGroceryStoreIcon />
                                                    </Badge>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>


                        <div className="best-sellers">
                            <h2>Bullet CCTV Cameras</h2>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div id="card" className="card-box">
                                    <div className="product-img">
                                        <img src={shop2} alt="product-image" className="shop1" />
                                    </div>

                                    <div className="cards">
                                        <div className="likeandhot">
                                            <Like />

                                            <Button className="spanhot1">HOT</Button>
                                        </div>
                                        <div className="product-img">
                                            <img src={product} alt="product-image" className="camera-1" />
                                        </div>

                                        {/* <img src={product} alt="product-image" className="camera-1" /> */}
                                        <div className="link-snack1">Category</div>
                                        <div className="link-fresh1">
                                            2 MP Build-in Mic Fixed Bullet Network Camera
                                        </div>
                                        <div className="price">
                                            <div className="price-clr">
                                                <p className="b1">₹2000</p>
                                                <span className="div4">₹5000</span>
                                            </div>

                                            <div className="rating">
                                                <Rating
                                                    name="hover-feedback"
                                                    value={rate}
                                                    precision={0.5}
                                                    getLabelText={getLabelText}
                                                    onChange={(event, newValue) => {
                                                        setRate(newValue);
                                                    }}
                                                    onChangeActive={(event, newHover) => {
                                                        setHover(newHover);
                                                    }}
                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                />
                                                {value !== null && (
                                                    <Box sx={{ ml: 1 }}>{labels[hover !== -1 ? hover : value]}</Box>
                                                )}
                                            </div>

                                        </div>
                                        <div className="compare">
                                            <div className="frame-parent2">
                                                <input className="frame-input" type="checkbox" />
                                                <b className="link-vegetables1">Compare</b>
                                            </div>
                                            <div className="addtocart">
                                                <Button className="buy-now">Buy Now</Button>
                                                <div className="badge">
                                                    <Badge >
                                                        <LocalGroceryStoreIcon />
                                                    </Badge>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <div className="cards">
                                        <div className="likeandhot">
                                            <Like />

                                            <Button className="spanhot1">HOT</Button>
                                        </div>
                                        <div className="product-img">
                                            <img src={product} alt="product-image" className="camera-1" />
                                        </div>

                                        {/* <img src={product} alt="product-image" className="camera-1" /> */}
                                        <div className="link-snack1">Category</div>
                                        <div className="link-fresh1">
                                            2 MP Build-in Mic Fixed Bullet Network Camera
                                        </div>
                                        <div className="price">
                                            <div className="price-clr">
                                                <p className="b1">₹2000</p>
                                                <span className="div4">₹5000</span>
                                            </div>

                                            <div className="rating">
                                                <Rating
                                                    name="hover-feedback"
                                                    value={rate}
                                                    precision={0.5}
                                                    getLabelText={getLabelText}
                                                    onChange={(event, newValue) => {
                                                        setRate(newValue);
                                                    }}
                                                    onChangeActive={(event, newHover) => {
                                                        setHover(newHover);
                                                    }}
                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                />
                                                {value !== null && (
                                                    <Box sx={{ ml: 1 }}>{labels[hover !== -1 ? hover : value]}</Box>
                                                )}
                                            </div>

                                        </div>
                                        <div className="compare">
                                            <div className="frame-parent2">
                                                <input className="frame-input" type="checkbox" />
                                                <b className="link-vegetables1">Compare</b>
                                            </div>
                                            <div className="addtocart">
                                                <Button className="buy-now">Buy Now</Button>
                                                <div className="badge">
                                                    <Badge >
                                                        <LocalGroceryStoreIcon />
                                                    </Badge>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <div className="cards">
                                        <div className="likeandhot">
                                            <Like />

                                            <Button className="spanhot1">HOT</Button>
                                        </div>
                                        <div className="product-img">
                                            <img src={product} alt="product-image" className="camera-1" />
                                        </div>

                                        {/* <img src={product} alt="product-image" className="camera-1" /> */}
                                        <div className="link-snack1">Category</div>
                                        <div className="link-fresh1">
                                            2 MP Build-in Mic Fixed Bullet Network Camera
                                        </div>
                                        <div className="price">
                                            <div className="price-clr">
                                                <p className="b1">₹2000</p>
                                                <span className="div4">₹5000</span>
                                            </div>

                                            <div className="rating">
                                                <Rating
                                                    name="hover-feedback"
                                                    value={rate}
                                                    precision={0.5}
                                                    getLabelText={getLabelText}
                                                    onChange={(event, newValue) => {
                                                        setRate(newValue);
                                                    }}
                                                    onChangeActive={(event, newHover) => {
                                                        setHover(newHover);
                                                    }}
                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                />
                                                {value !== null && (
                                                    <Box sx={{ ml: 1 }}>{labels[hover !== -1 ? hover : value]}</Box>
                                                )}
                                            </div>

                                        </div>
                                        <div className="compare">
                                            <div className="frame-parent2">
                                                <input className="frame-input" type="checkbox" />
                                                <b className="link-vegetables1">Compare</b>
                                            </div>
                                            <div className="addtocart">
                                                <Button className="buy-now">Buy Now</Button>
                                                <div className="badge">
                                                    <Badge >
                                                        <LocalGroceryStoreIcon />
                                                    </Badge>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <div className="cards">
                                        <div className="likeandhot">
                                            <Like />

                                            <Button className="spanhot1">HOT</Button>
                                        </div>
                                        <div className="product-img">
                                            <img src={product} alt="product-image" className="camera-1" />
                                        </div>

                                        {/* <img src={product} alt="product-image" className="camera-1" /> */}
                                        <div className="link-snack1">Category</div>
                                        <div className="link-fresh1">
                                            2 MP Build-in Mic Fixed Bullet Network Camera
                                        </div>
                                        <div className="price">
                                            <div className="price-clr">
                                                <p className="b1">₹2000</p>
                                                <span className="div4">₹5000</span>
                                            </div>

                                            <div className="rating">
                                                <Rating
                                                    name="hover-feedback"
                                                    value={rate}
                                                    precision={0.5}
                                                    getLabelText={getLabelText}
                                                    onChange={(event, newValue) => {
                                                        setRate(newValue);
                                                    }}
                                                    onChangeActive={(event, newHover) => {
                                                        setHover(newHover);
                                                    }}
                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                />
                                                {value !== null && (
                                                    <Box sx={{ ml: 1 }}>{labels[hover !== -1 ? hover : value]}</Box>
                                                )}
                                            </div>

                                        </div>
                                        <div className="compare">
                                            <div className="frame-parent2">
                                                <input className="frame-input" type="checkbox" />
                                                <b className="link-vegetables1">Compare</b>
                                            </div>
                                            <div className="addtocart">
                                                <Button className="buy-now">Buy Now</Button>
                                                <div className="badge">
                                                    <Badge >
                                                        <LocalGroceryStoreIcon />
                                                    </Badge>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>


                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <div className="footer-up">
                                    <img src={fotertop1} alt='banner-img' />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <div className="footer-up">
                                    <img src={fotertop2} alt='banner-img' />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <div className="footer-up">
                                    <img src={fotertop3} alt='banner-img' />
                                </div>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Footer />
                    </Grid>

                </Grid>
            </Box>

        </div >
    )
}

export default Homepage
