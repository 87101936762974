// import React from 'react'
// import Accordion from '@mui/material/Accordion';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import './Dropdown.css'
// import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';
// import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
// import Button from '@mui/material/Button';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import camera from '../../image/camera.svg'
// import network from '../../image/network.svg'
// import ip from '../../image/ip.svg'
// import analog from '../../image/analog.svg'
// import cup from '../../image/cup.svg'
// import snowflake from '../../image/snowflake.svg'
// import bar from '../../image/bar.svg'
// import wheat from '../../image/wheat.svg'
// import scoop from '../../image/scoop.svg'
// import toilet from '../../image/toilet.svg'
// import babyboy from '../../image/babyboy.svg'


// const Sidebar = () => {

//     const [expanded, setExpanded] = React.useState(false);

//     const handleChange = (panel) => (event, isExpanded) => {
//         setExpanded(isExpanded ? panel : false);
//     };

//     const [anchorEl, setAnchorEl] = React.useState(null);
//     const open = Boolean(anchorEl);
//     const handleClick = (event) => {
//         setAnchorEl(event.currentTarget);
//     };
//     const handleClose = () => {
//         setAnchorEl(null);
//     };

//     return (
//         <div className='sidebar-page'>

//             <Box sx={{ display: 'flex' }} >
//                 <Grid container spacing={2} sx={{ justifyContent: 'center', padding: '0 24px' }}>
//                     <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

//                         <Accordion defaultExpanded>
//                             <AccordionSummary
//                                 expandIcon={<ExpandMoreIcon />}
//                                 aria-controls="panel1-content"
//                                 id="panel1-header"
//                                 className='acc-summary'
//                             >
//                                 <Typography sx={{ width: '100%', flexShrink: 0 }}>
//                                     <GridViewOutlinedIcon /> All Categories
//                                 </Typography>
//                             </AccordionSummary>
//                             <AccordionDetails className='acc-detail'>
//                                 <Typography>
//                                     <ul>
//                                         <li><img src={camera} alt='camera' /><span>Network Camera <KeyboardArrowRightIcon /></span></li>
//                                         <li><img src={network} alt='network' /><span>Network Video Recorder (NVR) <KeyboardArrowRightIcon /></span></li>
//                                         <li><img src={ip} alt='ip' /><span>IP and Analog Cameras <KeyboardArrowRightIcon /></span></li>
//                                         <li><img src={analog} alt='analog' /><span>Analog HD Camera <KeyboardArrowRightIcon /></span></li>
//                                         <li><img src={cup} alt='cup' /><span>Speed Dome(PTZ) <KeyboardArrowRightIcon /></span></li>
//                                         <li><img src={snowflake} alt='snowflake' /><span>Video Door Phones <KeyboardArrowRightIcon /></span></li>
//                                         <li><img src={bar} alt='bar' /><span>Time & Attendance <KeyboardArrowRightIcon /></span></li>
//                                         <li><img src={wheat} alt='wheat' /><span>Mobile Surveillance <KeyboardArrowRightIcon /></span></li>
//                                         <li><img src={scoop} alt='scoop' /><span>Beyond Video <KeyboardArrowRightIcon /></span></li>
//                                         <li><img src={toilet} alt='toilet' /><span>Video Wall and Controller <KeyboardArrowRightIcon /></span></li>
//                                         <li><img src={babyboy} alt='babyboy' /><span>Software <KeyboardArrowRightIcon /></span></li>

//                                     </ul>
//                                 </Typography>
//                             </AccordionDetails>
//                         </Accordion>
//                     </Grid>
//                 </Grid>
//             </Box>


//         </div>
//     )
// }

// export default Sidebar

import React, { useState } from 'react';
import './Dropdown.css';
import { Link } from "react-router-dom";
import Framemain from '../Framemain/Framemain';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import camera from '../../image/camera.svg'
import network from '../../image/network.svg'
import ip from '../../image/ip.svg'
import analog from '../../image/analog.svg'
import cup from '../../image/cup.svg'
import snowflake from '../../image/snowflake.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <div className="">
        <div className="dropdown-container">
          <button onClick={toggleDropdown} className="dropdown-button" >
           <div className='All-Categories'> All Categories</div> <span className='downarrow'><KeyboardArrowDownIcon /></span>
          </button>
          {isOpen && (
            <div className="dropdown-menu">
              <ul>
                <li className='dropdown-header'><Link to="/productlist"><img src={camera} alt='camera' /><span className='dropdown-header-list'>Network Camera <KeyboardArrowRightIcon /></span></Link></li><hr />
                <li className='dropdown-header'><Link to="/productlist2"><img src={network} alt='network' /><span className='dropdown-header-list'>Network Video Recorder (NVR) <KeyboardArrowRightIcon /></span></Link></li><hr />
                <li className='dropdown-header'><Link to="/Framemain"><img src={ip} alt='ip' /><span className='dropdown-header-list'>IP and Analog Cameras <KeyboardArrowRightIcon /></span></Link></li><hr />
                <li className='dropdown-header'><Link to="/Productcart"><img src={analog} alt='analog' /><span className='dropdown-header-list'>Analog HD Camera <KeyboardArrowRightIcon /></span></Link></li><hr />
                <li className='dropdown-header'><Link to=""><img src={cup} alt='cup' /><span className='dropdown-header-list'>Speed Dome(PTZ) <KeyboardArrowRightIcon /></span></Link></li><hr />
                <li className='dropdown-header'><Link to=""><img src={snowflake} alt='snowflake' /><span className='dropdown-header-list'>Video Door Phones <KeyboardArrowRightIcon /></span></Link></li><hr />
              </ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Dropdown;
