import React from "react";
import "./Loginpage.css";
import Grid from "@mui/material/Grid";
import { Button, IconButton } from "@mui/material";
import Badge from "@mui/material/Badge";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import Rating from "../Productlist2/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import image1 from "../../image/4.png";
import SideBar from "../SideBar/SideBar";   
import Typography from '@mui/material/Typography';
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Rectanglemain from '../../image/Rectangle main.png'
import { CheckBox } from "@mui/icons-material";




function framemain() {


  return (
    <div className="framepage">
      <div>
        <Header />
      </div>
      <div className='frameleftandright'>
        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <div>    
                <div className="frame-left">
                    <div>
                        <h2 className="login-register">Returning customer?Click here to <a href="">Login</a> Or <a href="">Register</a></h2>
                        <p className="login-para">If the user already signed will navigate to shipping address fielsd always, as the new user need to login or create account.</p>
                    </div>
                    <div >
                        <input className="email-box" placeholder="Enter your email address"></input>
                        
                    </div>
                    <div >
                        <input className="email-box" placeholder="Enter Password"></input>
                    </div>
                    <div>
                        <Button className="button-login">
                            Login
                        </Button>
                    </div>
                    <div>
                        <input type="checkbox" className="checkbox-1"></input>
                        <span  className="checkbox-font">You”ll be able to save your details to create an account later.</span>
                    </div>                    
                    <div>
                        <input type="checkbox" className="checkbox-2"></input>
                        <span >Keep me up to date on exclusive offers</span>
                    </div>                    
                </div>
            </div>
            </Grid>     
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <div>   
                <div className="frame-right">
                    <div className="right-box-mycart">
                        <div>
                            <div>
                                <h3>Your Order</h3>
                            </div>
                            <div className="total-mycart">
                                <p>Total Amount</p>
                                <p>₹10,651.00</p>
                            </div>
                            <div className="total-mycart">
                                <p>Total GST</p>
                                <p>₹1,918.00</p>
                            </div>
                            <div className="total-mycart">
                                <p>Total Shipping</p>
                                <p>₹0.00</p>
                            </div>
                            <div className="total-mycart">
                                <p>Total Coupon Discount</p>
                                <p className="total-price-mycart">₹0.00</p>
                            
                            </div>
                            <hr className="mycart" />
                            <div className="total-mycart">
                                <h5>Total </h5>
                                <h5>₹12,569.00</h5>
                            </div>
                            <div className="confirm-order-button-main">
                                <Button className="confirm-order-button">CONFIRM YOUR ORDER</Button>
                            </div>
                        </div>
                        <div>
                        <div>
                            <div className="essential-heading">
                                <h3>Essential Combo</h3>
                            </div>
                            <hr className="checkbox-hr"/>
                            <div className="checkbox-image-main">
                                <input type='checkbox' className="checkbox-main"></input>
                                <img src ={Rectanglemain} alt="Rectanglemain" className="checkbox-Rectanglemain"></img>
                                <div className="checkbox-para">
                                    <h5> 8MP Outdoor AI Surveillance Camera</h5>
                                    <p>These combo offers cater to a range of customer preferences and requirements</p>
                                </div>
                            </div>
                            <hr  className="checkbox-hr"/>
                            <div className="checkbox-image-main">
                                <input type='checkbox' className="checkbox-main"></input>
                                <img src ={Rectanglemain} alt="Rectanglemain" className="checkbox-Rectanglemain"></img>
                                <div className="checkbox-para">
                                    <h5> 8MP Outdoor AI Surveillance Camera</h5>
                                    <p>These combo offers cater to a range of customer preferences and requirements</p>
                                </div>
                            </div>
                            <hr  className="checkbox-hr"/>
                            <div className="checkbox-image-main">
                                <input type='checkbox' className="checkbox-main"></input>
                                <img src ={Rectanglemain} alt="Rectanglemain" className="checkbox-Rectanglemain"></img>
                                <div className="checkbox-para">
                                    <h5> 8MP Outdoor AI Surveillance Camera</h5>
                                    <p>These combo offers cater to a range of customer preferences and requirements</p>
                                </div>
                            </div>
                            <hr  className="checkbox-hr"/>
                            <div className="checkbox-image-main">
                                <input type='checkbox' className="checkbox-main"></input>
                                <img src ={Rectanglemain} alt="Rectanglemain" className="checkbox-Rectanglemain"></img>
                                <div className="checkbox-para">
                                    <h5> 8MP Outdoor AI Surveillance Camera</h5>
                                    <p>These combo offers cater to a range of customer preferences and requirements</p>
                                </div>
                            </div>
                            <hr  className="checkbox-hr"/>
                            <div className="checkbox-image-main">
                                <input type='checkbox' className="checkbox-main"></input>
                                <img src ={Rectanglemain} alt="Rectanglemain" className="checkbox-Rectanglemain"></img>
                                <div className="checkbox-para">
                                    <h5> 8MP Outdoor AI Surveillance Camera</h5>
                                    <p>These combo offers cater to a range of customer preferences and requirements</p>
                                </div>
                            </div>
                            <hr  className="checkbox-hr"/>
                        </div>
                        <div>
                            <div className="essential-heading">
                                <h3>Essential Combo</h3>
                            </div>
                            <hr className="checkbox-hr"/>
                            <div className="checkbox-image-main">
                                <input type='checkbox' className="checkbox-main"></input>
                                <img src ={Rectanglemain} alt="Rectanglemain" className="checkbox-Rectanglemain"></img>
                                <div className="checkbox-para">
                                    <h5> 8MP Outdoor AI Surveillance Camera</h5>
                                    <p>These combo offers cater to a range of customer preferences and requirements</p>
                                </div>
                            </div>
                            <hr  className="checkbox-hr"/>
                            <div className="checkbox-image-main">
                                <input type='checkbox' className="checkbox-main"></input>
                                <img src ={Rectanglemain} alt="Rectanglemain" className="checkbox-Rectanglemain"></img>
                                <div className="checkbox-para">
                                    <h5> 8MP Outdoor AI Surveillance Camera</h5>
                                    <p>These combo offers cater to a range of customer preferences and requirements</p>
                                </div>
                            </div>
                            <hr  className="checkbox-hr"/>
                            <div className="checkbox-image-main">
                                <input type='checkbox' className="checkbox-main"></input>
                                <img src ={Rectanglemain} alt="Rectanglemain" className="checkbox-Rectanglemain"></img>
                                <div className="checkbox-para">
                                    <h5> 8MP Outdoor AI Surveillance Camera</h5>
                                    <p>These combo offers cater to a range of customer preferences and requirements</p>
                                </div>
                            </div>
                            <hr  className="checkbox-hr"/>
                        </div>
                        </div>

                    </div>

                </div>
            </div> 
            </Grid>
        </Grid>

        </div>
        <div>
            <Footer />
        </div>
    </div>
  );
}

export default framemain;
