import React from "react";
import "./Productcart.css";
import Grid from "@mui/material/Grid";
import { Button, IconButton } from "@mui/material";
import Badge from "@mui/material/Badge";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import Rating from "../Productlist2/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import image1 from "../../image/4.png";
import SideBar from "../SideBar/SideBar";   
import Typography from '@mui/material/Typography';
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Rectanglemain from '../../image/Rectangle main.png'
import { CheckBox } from "@mui/icons-material";




function framemain() {


  return (
    <div className="framepage">
      <div>
        <Header />
      </div>
      <div className='frameleftandright'>
        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <div>    
                
            </div>
            </Grid>     
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <div>   
                    
                </div> 
            </Grid>
        </Grid>
            <div>

            </div>

        </div>
        <div>
            <Footer />
        </div>
    </div>
  );
}

export default framemain;
