import './App.css';
import { BrowserRouter as Router, Routes,  Route } from "react-router-dom";
import Header from './Components/Header/Header';
import Homepage from './Components/Homepage/Homepage';
import Productlist from './Components/Productlist/Productlist';
import Productlist2 from './Components/Productlist2/Productlist';
import Framemain from './Components/Framemain/Framemain'
import Productcart from './Components/Productcart/Productcart'
import Loginpage from './Components/Loginpage/Loginpage'
import Mycart from './Components/Mycart/Mycart';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/productlist" element={<Productlist />} />
          <Route exact path="/productlist2" element={<Productlist2 />} />
          <Route exact path="/Framemain" element={<Framemain />} />
          <Route exact path="/Productcart" element={<Productcart />} />
          <Route exact path="/Loginpage" element={<Loginpage />} />
          <Route exact path="/Mycart" element={<Mycart />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
